<template>
  <div>
    <!--begin::Signup-->
    <div class="login-form login-signin">
      <div class="mb-10 mb-lg-10">
        <h3 class="font-size-h1 font-weight-normal">회원가입</h3>
        <p class="font-weight-semi-bold font-size-lg"></p>
      </div>
      <!--begin::Form-->
      <div class="form">
        <b-form-group
          id="example-input-group-0"
          label=""
          label-for="example-input-0"
        >
          <div class="d-flex justify-content-between">
            <label>아이디</label>
          </div>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-bind:class="{'is-invalid': idValidate}"
            id="example-input-0"
            name="example-input-0"
            size="lg"
            v-model="$v.form.username.$model"
            :state="validateState('username')"
            aria-describedby="input-0-live-feedback"
            placeholder="ID"
            maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-0-live-feedback">
            {{ idValidate ? '중복된 아이디가 존재합니다.' : '아이디를 입력해주세요.'}}

          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <div class="d-flex justify-content-between">
            <label>비밀번호</label>
          </div>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            size="lg"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            placeholder="Password"
            maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            비밀번호를 입력해주세요.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-5"
          label=""
          label-for="example-input-5"
        >
          <div class="d-flex justify-content-between">
            <label>비밀번호 확인</label>
          </div>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-5"
            name="example-input-5"
            size="lg"
            v-model="$v.form.passwordConfirm.$model"
            :state="validateState('passwordConfirm')"
            aria-describedby="input-5-live-feedback"
            placeholder="Password Confirm"
            maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-5-live-feedback">
            비밀번호를 확인해주세요.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-4"
            label=""
            label-for="example-input-4"
        >
          <div class="d-flex justify-content-between">
            <label>이름</label>
          </div>
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              id="example-input-4"
              name="example-input-4"
              size="lg"
              v-model="$v.form.name.$model"
              :state="validateState('name')"
              aria-describedby="input-4-live-feedback"
              placeholder="Username"
              maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-4-live-feedback">
            이름을 입력해주세요.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <div class="d-flex justify-content-between">
            <label>이메일</label>
          </div>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-bind:class="{'is-invalid': emailValidate}"
            id="example-input-1"
            name="example-input-1"
            size="lg"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
            placeholder="Email address"
            maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            이메일을 올바르게 입력해주세요.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-3"
            label=""
            label-for="example-input-3"
        >
          <div class="d-flex justify-content-between">
            <label>휴대폰 번호</label>
          </div>
<!--          <b-form-input-->
<!--              class="form-control form-control-solid h-auto py-5 px-6"-->
<!--              v-bind:class="{'is-invalid': phoneNoValidate}"-->
<!--              id="example-input-3"-->
<!--              name="example-input-3"-->
<!--              size="lg"-->
<!--              v-model="$v.form.phoneNo.$model"-->
<!--              :state="validateState('phoneNo')"-->
<!--              aria-describedby="input-3-live-feedback"-->
<!--              placeholder="Phone Num (only number)"-->
<!--          ></b-form-input>-->
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              v-bind:class="{'is-invalid': phoneNoValidate}"
              id="example-input-3"
              name="example-input-3"
              size="lg"
              v-model="form.phoneNo"
              aria-describedby="input-3-live-feedback"
              placeholder="Phone Num (only number)"
              maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-3-live-feedback">
            올바른 휴대폰 번호를 입력해주세요. ( - 제외 숫자만 입력)
          </b-form-invalid-feedback>
        </b-form-group>


        <!--begin::Action-->
        <div class="form-group d-flex flex-center">
          <b-button
            variant="primary"
            ref="signupBtn"
            class="px-9 py-4 my-3 mr-3"
            @click="onSubmit"
          >
            <span class="font-weight-bold font-size-h5">회원가입</span>
          </b-button>
          <b-button
            variant="light-primary"
            class="px-9 py-4 my-3"
            v-on:click="$router.push('signin')"
          >
            <span class="font-weight-bold font-size-h5">취소</span>
          </b-button>
        </div>
        <!--end::Action-->
      </div>
      <!--end::Form-->
    </div>
    <!--end::Signup-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {REGISTER} from '@/core/services/store/auth.module';

import {validationMixin} from 'vuelidate';
import {email, minLength, required, sameAs} from 'vuelidate/lib/validators';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';
import {getResult, isSuccess, checkOnlyNumber} from '@/core/services/funcs';
import Vue from "vue";


export default {
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      form: {
        username: '',
        name: '',
        email: '',
        phoneNo: '',
        password: '',
        passwordConfirm: '',
      },
      loaded: false,

      errors: {
        '301': 'idValidate',
        '302': 'emailValidate',
        '303': 'phoneNoValidate',
      },
      idValidate: false,
      emailValidate: false,
      phoneNoValidate: false
    };
  },
  watch: {
    'form.username'(){
      if(this.idValidate) this.idValidate = false
    },
    'form.email'(){
      if(this.emailValidate) this.emailValidate = false
    },
    'form.phoneNo'(){
      if(this.phoneNoValidate) this.phoneNoValidate = false
    },
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3)
      },
      name: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      // phoneNo: {
      //   numeric,
      //   minLength: minLength(8)
      // },
      password: {
        required,
        minLength: minLength(3)
      },
      passwordConfirm: {
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      if(this.loaded) return;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      if(!this.checkPhoneNumber()){
        this.phoneNoValidate = true;
        return;
      }

      const username = this.$v.form.username.$model;
      const name = this.$v.form.name.$model;

      const email = this.$v.form.email.$model;
      // const phoneNo = this.$v.form.phoneNo.$model;
      const phoneNo = this.form.phoneNo;
      const password = this.$v.form.password.$model;

      this.$refs.signupBtn.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store.dispatch(REGISTER, {
        id: username,
        name: name,
        email: email,
        password: password,
        phoneNo: phoneNo
      })
      .then(resp => {
        if(isSuccess(resp)){
            let info = {}
            info.icon = 'info'
            info.title = '회원가입에 성공했습니다. 이메일 인증 후 로그인 해주시기 바랍니다.'
            info.reverseButtons = true
            info.focusCancel = false
            info.confirmButtonColor = '#131628'
            info.confirmButtonText = `확인`
            info.showCancelButton = false
            Vue.swal.fire(info).then(result => {
              if (result.value) {
                this.$router.push({ name: "signin" })
              }
            })
        }else{
          const result = getResult(resp);
          this.$store.dispatch(ACT_ADD_ALERT, {message: result.message});
          this.setError(result.number);
          this.clearLoaded();
        }
      })
      .catch(e => {
        console.error(e);
        this.$store.dispatch(ACT_ADD_ALERT, {message: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.clearLoaded();
      });
    },
    clearLoaded(){
      this.$refs.signupBtn.classList.remove("spinner", "spinner-light", "spinner-right");
      this.loaded = false
    },
    setError(number){
      if(number && this.errors[number]) {
        this[this.errors[number]] = true;
      }
    },
    checkPhoneNumber(){
      // 휴대폰 번호를 입력했을 경우 숫자만 입력하다록 적용
      if(this.form.phoneNo) return checkOnlyNumber(this.form.phoneNo);
      return true;
    }
  },
};
</script>
